import { defineStore } from 'pinia'
import useUserStore from '~/store/user'
import { getI18nLanguageId } from '~/utils/languages'

interface AppleLoginParams {
  Code: string
  FirstName: string
  JwtToken: string
  LastName: string
  Nonce: string
}

const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    errorMessage: '',
    showSuccessLoginMessage: false,
  }),
  actions: {
    async handleMeAfterLogin(callback) {
      const userStore = useUserStore()
      const userHasAuth = await userStore.getUserMe()
      const userHasPersonalData = await userStore.loadUserData()

      this.showSuccessLoginMessage = userHasAuth && userHasPersonalData

      if (userHasAuth && userHasPersonalData) {
        const currentDomain = window.location.hostname
        const referrer = document.referrer
        const hasReferrer = referrer !== '' && referrer.includes(currentDomain)

        if (hasReferrer) {
          window.location.href = referrer
          return
        }

        callback()
      }
    },
    async login(loginParams, callback) {
      const { email, password, hCaptchaToken, staySignedIn } = loginParams

      return useFetch('/api/auth/login/default-login', {
        method: 'POST',
        body: JSON.stringify({
          email,
          password,
          'h-captcha-response': hCaptchaToken,
          staySignedIn,
          languageId: getI18nLanguageId(),
        }),
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      })
        .then(async (response) => {
          if (response?.error?.value) {
            this.showSuccessLoginMessage = false
            return false
          }

          await this.handleMeAfterLogin(callback)

          return true
        })
        .catch(() => {
          this.showSuccessLoginMessage = false

          return false
        })
    },
    async fetchGoogleLogin({ JwtToken }, callbackLogin) {
      return $fetch('/api/auth/login/google', {
        method: 'POST',
        body: {
          JwtToken,
          languageId: getI18nLanguageId(),
        },
        credentials: 'include',
      })
        .then(async (response) => {
          // console.log('response google fetch', response)

          await this.handleMeAfterLogin(callbackLogin)
          return true
        })
        .catch((e) => {
          console.error('Error on /v1/google/login', e)
          return false
        })
    },
    async fetchAppleLogin(params: AppleLoginParams, callbackLogin) {
      return await $fetch('/api/auth/login/apple', {
        method: 'POST',
        body: {
          ...params,
          languageId: getI18nLanguageId(),
        },
        credentials: 'include',
      })
        .then(async (response) => {
          // console.log('response apple fetch', response)

          await this.handleMeAfterLogin(callbackLogin)

          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        })
    },
    async fetchFacebookLogin({ accessToken }: { accessToken: string }, callbackLogin) {
      try {
        await useFetch('/api/auth/login/facebook', {
          method: 'POST',
          body: {
            accessToken,
            languageId: getI18nLanguageId(),
          },
          credentials: 'include',
        })

        await this.handleMeAfterLogin(callbackLogin)
        return true
      } catch {
        return false
      }
    },
    async resetPassword(resetCode: string, newPassword: string) {
      try {
        await useFetch('/api/auth/reset-password', {
          method: 'PUT',
          body: {
            code: resetCode,
            password: newPassword,
            languageId: getI18nLanguageId(),
          },
        })

        return true
      } catch {
        return false
      }
    },
    async logout() {
      try {
        await useFetch('/api/auth/logout', {
          method: 'GET',
          credentials: 'include',
        })

        const userStore = useUserStore()
        userStore.resetUser()
      } catch {
        return false
      }
    },
    async forgotPassword({ email, hCaptchaToken }) {
      return await useFetch('/api/auth/forgot-password', {
        method: 'POST',
        body: {
          email,
          'h-captcha-response': hCaptchaToken,
          languageId: getI18nLanguageId(),
        },
      })
        .then(() => {
          return true
        })
        .catch((error) => {
          throw error
        })
    },
  },
})

export default useAuthStore
